<template>
    <div class="mx-auto max-w-wide px-4 sm:px-6 lg:px-8">
        <div class="bg-white">
            <StoryblokComponent v-if="story" :blok="story.content" />
        </div>
    </div>
</template>

<script setup lang="ts">
import { useStoryblokDealerMe } from '~/composables/storyblok/useStoryblokDealerMe'
import { useStoryblokHead } from '~/composables/storyblok/useStoryblokHead'
import type { StoryData } from '~/utils/types/storyblok'

let { slug } = useRoute().params
slug = slug !== '' ? (Array.isArray(slug) ? slug : [slug]) : ['home']

const url = slug && slug.length > 0 ? slug.join('/') : 'home'

let story: StoryData | null = null

const res: any = await useStoryblokDealerMe({
    slug: 'pages/' + url.replace(/\/$/, ''),
})

if (!res.value) {
    showError({
        statusCode: 404,
        statusMessage: 'Page Not Found',
    })
} else {
    story = res

    if (story) {
        useStoryblokHead(res.value)
    }
}
</script>
